import React, { Suspense } from 'react';
import { ConfigProvider } from 'antd';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { Skeleton } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import { createBrowserHistory } from 'history';

import config from './config';

const history = createBrowserHistory();

class AppRouter extends React.Component {
    render() {
        return (
            <ConfigProvider locale={zhCN}>
                <BrowserRouter>
                    <Suspense fallback={<Skeleton active />}>
                        <Switch>
                            {config.map(item => {
                                return (
                                    <Route
                                        key={item.path}
                                        path={item.path}
                                        exact={item.exact !== false}
                                        component={item.component}
                                    />
                                );
                            })}
                        </Switch>
                    </Suspense>
                </BrowserRouter>
            </ConfigProvider>
        );
    }
}

export { history, AppRouter };

import { lazy } from 'react';
import { Redirect } from 'react-router';
const Login = lazy(() => import('@/page/login'));
const Register = lazy(() => import('@/page/register'));
// const Profile = lazy(() => import('@/page/profile'));
const FindPsd = lazy(() => import('@/page/findpsd'));
const AdminLogin = lazy(() => import('@/page/adminLogin'));
const AdminPage = lazy(() => import('@/page/admin'));
const routeArr = [
    // {
    //     path: '/',
    //     component: Index,
    //     title: '首页',
    // },
    {
        path: '/login',
        component: Login,
        title: '登陆',
    },
    {
        path: '/register',
        component: Register,
        title: '注册',
    },
    // {
    //     path: '/profile',
    //     component: Profile,
    //     title: '个人中心',
    // },
    // {
    //     path: '/profile/content',
    //     component: ProfileContent,
    //     title: '上传的视频',
    // },
    // {
    //     path: '/video/:id',
    //     component: Video,
    //     title: '视频详情',
    //     exact: false,
    // },
    // {
    //     path: '/watch/:id',
    //     component: Watch,
    //     title: '播放页',
    // },
    {
        path: '/findpsd',
        component: FindPsd,
        title: '密码找回页',
    },
    // {
    //     path: '/feed/history',
    //     component: FeedHistory,
    //     title: '历史记录页',
    // },
    // {
    //     path: '/playlist/like',
    //     component: PlaylistLike,
    //     title: '赞过的视频',
    // },
    // 管理员 start
    {
        path: '/admin/login',
        component: AdminLogin,
        title: '管理登录',
    },
    {
        path: '/admin',
        component: AdminPage,
        title: '管理页',
    },
    // 管理员 end
    {
        path: '(.*)',
        component: () => <Redirect to="/" />,
        title: '首页',
    },
];

export default routeArr;
